<template>
  <div>
    <div class="cssnumber">
      <div class="cssnumber-name base">
        <span>{{ label }} </span>
      </div>
      <div class="cssnumber-input-group">
        <div class="cssnumber-input" :class="{ error }">
          <input v-model="internalValue" type="text">
        </div>
        <span v-if="error" class="cssnumber-error">
          use rem, em, px or % for a unit
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cssnumber-error {
  font-size: .8rem;
  padding-right: .5rem;
  color: red;
}

.cssnumber-input.error {
  border-color:red;
}
.cssnumber {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    padding-right: 34px;
}
.cssnumber, .cssnumber-name {
    display: flex;
    align-items: center;
}
.cssnumber-input-group {
    display: flex;
    position: relative;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.cssnumber-input {
    -webkit-transition: border .3s;
    transition: border .3s;
    border: 1px solid #d4d9e1;
    border-radius: 4px;
    height: 28px;
    max-width: 90px;
}
.cssnumber-input input {
    -webkit-appearance: none;
    border: 0;
    outline: none;
    font-size: 12px;
    width: 60px;
    background: transparent;
    padding: 0 5px;
}
</style>

<script>
export default {
  props: ['value', 'label'],
  data () {
    return {
      internalValue: '0rem',
      error: false,
    };
  },
  watch: {
    internalValue () {
      if (this.internalValue === '' || /^(-)?([0-9]+)?(\.[0-9]+)?(em|rem|%|px|pt)$/i.test(this.internalValue)) {
        this.error = false;
        this.$emit('input', this.internalValue !== '' ? this.internalValue : null);
      } else {
        this.error = true;
      }
    }
  },
  created () {
    if (this.value) {
      this.internalValue = this.value;
    }
  },
};
</script>
