<template>
  <div>
    <div class="color">
      <div class="color-name" :class="{ base: noVariations === undefined }" @click="() => { if (noVariations === undefined) { display = !display; } }">
        <i class="color-swatch" :style="{ backgroundColor: color }" /><span>{{ label }} </span>
      </div>
      <div class="color-input-group">
        <div class="color-input" :class="{ error }">
          <div class="color-wrap" :style="{ backgroundColor: color }">
            <input v-model="hex" type="color" tabindex="-1">
          </div><input v-model="hex" type="text">
        </div>
        <span v-if="error" class="color-error">Please enter a valid six digit hex code. &nbsp; &nbsp;</span>
      </div>
    </div>
    <ul class="controls-sub" :style="{ display: (display && noVariations === undefined ? 'block' : 'none') }">
      <li>
        <div class="color">
          <div class="color-name">
            <i class="color-swatch" :style="{ backgroundColor: shade }" /><span>{{ label }}-shade</span>
          </div><div class="color-hex">
            <span>{{ shade }}</span>
          </div>
        </div>
      </li>
      <li>
        <div class="color">
          <div class="color-name">
            <i class="color-swatch" :style="{ backgroundColor: tint }" /><span>{{ label }}-tint</span>
          </div><div class="color-hex">
            <span>{{ tint }}</span>
          </div>
        </div>
      </li>
      <li>
        <div class="color">
          <div class="color-name">
            <i class="color-swatch" :style="{ backgroundColor: contrast }" /><span>{{ label }}-contrast</span>
          </div><div class="color-hex">
            <span>{{ contrast }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.color-error {
  font-size: .8rem;
  color: red;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.color-hex {
    width: 94px;
}
.color-hex span {
    font-size: 12px;
    padding: 3px 5px;
    border-radius: 2px;
    background-color: #dfdfdf;
    color: #000;
    padding: 3px 5px;
    border-radius: 2px;
}
.color {
    justify-content: space-between;
    padding: 12px;
    padding-right: 34px;
}
.color, .color-name {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}
.color-input-group, .color-input {
    display: flex;
    align-items: center;
}
.color-input-group {
    position: relative;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}
.color-name {
    font-size: 14px;
    font-weight: 500;
}
.color-name.base {
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
.color-swatch {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-right: 16px;
    border: 1px solid rgba(0,0,0,.1);
}
.color-input {
    -webkit-transition: border .3s;
    transition: border .3s;
    border: 1px solid #d4d9e1;
    border-radius: 4px;
    height: 28px;
    max-width: 90px;
}
.color-input.error {
  border-color:red;
}
.color-wrap {
    border: 1px solid rgba(0,0,0,.1);
    width: 12px;
    height: 12px;
    margin: 0 7px;
    border-radius: 2px;
    display: inline-block;
}
.color-input-name input, .color-input input {
    -webkit-appearance: none;
    border: 0;
    outline: none;
    font-size: 12px;
    width: 60px;
    background: transparent;
}
.color-input input[type=color] {
    -webkit-appearance: none;
    border: none;
    outline: none;
    opacity: 0;
    width: 12px;
    height: 12px;
    display: block;
    margin: 0;
    padding: 0;
}
</style>

<script>
import colorHelpers from './libs/color-helpers';
export default {
  props: ['default', 'label', 'noVariations'],
  data () {
    return {
      display: false,
      hex: '#dddddd',
      color: '#dddddd',
      shade: '#dddddd',
      tint: '#dddddd',
      contrast: '#ffffff',
      error: false
    };
  },
  watch: {
    hex (value){
      if (!value.startsWith('#')) {
        this.hex = '#' + this.hex;
        return;
      }
      if (!/^#[0-9A-F]{6}$/i.test(value)) {
        this.error = true;
        return;
      }
      this.error = false;
      this.color = value;
      this.shade = this.getColorShade(value);
      this.tint = this.getColorTint(value);
      this.contrast = colorHelpers.isDark(this.color) ? '#ffffff' : '#000000';
      if (this.noVariations === undefined) {
        this.$emit('input', {
          color: this.color,
          shade: this.shade,
          tint: this.tint,
          contrast: this.contrast,
          contrastRGB: colorHelpers.hexToRGB(this.contrast),
          colorRGB: colorHelpers.hexToRGB(this.color),
        });
      } else {
        this.$emit('input',  this.color);
      }
    },
  },
  mounted () {
    if (this.default) {
      this.hex = this.default;
    }
  },
  methods: {
    getColorShade (color) {
      return colorHelpers.mix('#000000', color, 12);
    },
    getColorTint (color) {
      return colorHelpers.mix('#ffffff', color, 10);
    },
  }

};
</script>
