export default {
  d2h (d) { return d.toString(16); },  // convert a decimal value to hex
  h2d (h) { return parseInt(h, 16); }, // convert a hex value to decimal
  mix (color1, color2, weight = 50) {
    const c1 = String(color1).replace('#', '');
    const c2 = String(color2).replace('#', '');
    let color = '#';
    for(let i = 0; i <= 5; i += 2) { // loop through each of the 3 hex pairs—red, green, and blue
      let v1 = this.h2d(c1.substr(i, 2)); // extract the current pairs
      let v2 = this.h2d(c2.substr(i, 2));
      // combine the current pairs from each source color, according to the specified weight
      let val = this.d2h(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));
      while(val.length < 2) { val = '0' + val; } // prepend a '0' if val results in a single digit
      color += val; // concatenate val to our new color string
    }
    return color;
  },
  hexToRGB (h) {
    let r = 0; let g = 0; let b = 0;
    // 3 digits
    if (h.length === 4) {
      r = '0x' + h[1] + h[1];
      g = '0x' + h[2] + h[2];
      b = '0x' + h[3] + h[3];
      // 6 digits
    } else if (h.length === 7) {
      r = '0x' + h[1] + h[2];
      g = '0x' + h[3] + h[4];
      b = '0x' + h[5] + h[6];
    }
    return parseInt(r) + ',' + parseInt(g) + ',' + parseInt(b);
  },
  isDark (h) {
    const rgb = this.hexToRGB(h);
    const rgbSplit = rgb.split(',');
    const r = rgbSplit[0];
    const g = rgbSplit[1];
    const b = rgbSplit[2];
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    const hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
    //return hsp>127.5 ? false : true;
    return hsp>150 ? false : true;
  },
};
