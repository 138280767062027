<template>
  <Sidebar v-if="base" :base="base">
    <div class="row padding">
      <div class="col-6">
        <h4>App Settings</h4>
        <b-form-group label-cols="6" label="Node color">
          <b-form-select v-model="settings.nodeColor" :options="mainColors" @input="emit" />
        </b-form-group>
        <b-form-group label-cols="6" label="Node modal icon color">
          <b-form-select v-model="settings.colorModal" :options="mainColors" @input="emit" />
        </b-form-group>
        <b-form-group label-cols="6" label="Node modal inputs color">
          <b-form-select v-model="settings.colorInputs" :options="mainColors" @input="emit" />
        </b-form-group>
        <b-form-group label-cols="6" label="Toolbar color">
          <b-form-select v-model="settings.toolbarColor" :options="mainColors" @input="emit" />
        </b-form-group>
        <br>
        <h4>Main colors</h4>
        <LarColorInput class="list" default="#780bb7" label="Primary" @input="(value) => { colors.primary = value; emit(); }" />
        <LarColorInput class="list" default="#7fb800" label="Secondary" @input="(value) => { colors.secondary = value; emit(); }" />
        <LarColorInput class="list" default="#f4f5f8" label="Light" @input="(value) => { colors.light = value; emit(); }" />
        <LarColorInput class="list" default="#d6d6d6" label="Medium" @input="(value) => { colors.medium = value; emit(); }" />
        <LarColorInput class="list" default="#222428" label="Dark" @input="(value) => { colors.dark = value; emit(); }" />
        <br>
        <h4>Helper colors</h4>
        <LarColorInput class="list" default="#00a6ed" label="Tertiary" @input="(value) => { colors.tertiary = value; emit(); }" />
        <LarColorInput class="list" default="#7fb800" label="Success" @input="(value) => { colors.success = value; emit(); }" />
        <LarColorInput class="list" default="#ffb400" label="Warning" @input="(value) => { colors.warning = value; emit(); }" />
        <LarColorInput class="list" default="#f6511d" label="Danger" @input="(value) => { colors.danger = value; emit(); }" />
        <br>
        <h4>App colors</h4>
        <LarColorInput class="list" :default="appColors.bg" no-variations label="Background" @input="(value) => { appColors.bg = value; emit(); }" />
        <LarColorInput class="list" :default="appColors.text" no-variations label="Text" @input="(value) => { appColors.text = value; emit(); }" />
        <br>
        <h4>Nodes</h4>
        <LarColorInput class="list" :default="variables['lar-node-icon-small-border-color']" no-variations label="Node Icon border color" @input="(value) => { variables['lar-node-icon-small-border-color'] = value; emit(); }" />
        <LarCssNumberInput v-model="variables['lar-node-box-border-radius']" class="list" label="Node radius" @input="emit" />
        <LarCssNumberInput v-model="variables['lar-node-icon-small-border-radius']" class="list" label="Node small icon radius" @input="emit" />
        <LarCssNumberInput v-model="variables['lar-node-icon-small-border-width']" class="list" label="Node icon border width" @input="emit" />
        <LarCssNumberInput v-model="variables['lar-node-icon-small-size']" class="list" label="Node icon size" @input="emit" />
        <LarCssNumberInput v-model="variables['lar-node-icon-small-top']" class="list" label="Node icon top" @input="emit" />
        <LarCssNumberInput v-model="variables['lar-node-icon-small-left']" class="list" label="Node icon left" @input="emit" />
        <br>
        <b-button v-b-modal.modal-1>
          Theme Code
        </b-button>

        <b-modal id="modal-1" size="lg" ok-only title="Theme">
          <vue-markdown :source="'```css\r\n' + cssText + '\r\n```'" @rendered="window.setTimeout(() => { window.Prism.highlightAll();});" />
        </b-modal>
      </div>
      <div
        class="col-6 device-wrapper"
      >
        <div class="device">
          <svg class="ios-notch" viewBox="0 0 219 31"><path d="M0 1V0h219v1a5 5 0 0 0-5 5v3c0 12.15-9.85 22-22 22H27C14.85 31 5 21.15 5 9V6a5 5 0 0 0-5-5z" fill-rule="evenodd" /></svg>
          <iframe ref="deviceframe" src="/demo-app/index.html" @load="emit" />
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
// TODO: source v-if and timeout
import Sidebar from '../components/sidebar';
import LarColorInput from '../components/lar-color-input';
import LarCssNumberInput from '../components/lar-css-number-input';
import VueMarkdown from 'vue-markdown';
import colorHelpers from '../components/libs/color-helpers';

export default {
  components: {
    Sidebar,
    LarColorInput,
    VueMarkdown,
    LarCssNumberInput
  },
  data () {
    return {
      base: undefined,
      cssText: '',
      window,
      console,
      colors: {
        primary: {}, //
        secondary: {}, //
        tertiary: {}, //
        success: {}, //
        warning: {}, //
        danger: {}, //
        light: {}, //
        medium: {}, //
        dark: {}, //
      },
      appColors: {
        bg: '#000000',
        text: '#ffffff',
      },
      variables: {
        'lar-node-box-border-radius': '1rem',
        'lar-node-icon-small-border-radius': '50%',
        'lar-node-icon-small-border-width': '0.3rem',
        'lar-button-border-radius	': '.6rem',
        'lar-button-border-radius-small': '1.2rem',
        'lar-node-icon-small-top': '-1rem',
        'lar-node-icon-small-left': '-1rem',
        'lar-node-icon-small-size': '1.7rem',
        'lar-node-icon-small-border-color': '#000000'
      },
      settings: {
        nodeColor: 'primary',
        toolbarColor: 'primary',
        colorModal: 'primary',
        colorInputs: 'primary',
      },
      mainColors: [
          { value: 'primary', text: 'Primary' },
          { value: 'secondary', text: 'Secondary' },
          { value: 'light', text: 'Light' },
          { value: 'medium', text: 'Medium' },
          { value: 'dark', text: 'Dark' },
        ]
    };
  },
  mounted () {
    this.base = this.$route.params.base;
    this.file = this.$route.params.file;
  },
  methods: {
    emit () {
      let cssText = '';
      // base colors
      for (let property in this.colors) {
        if (!this.colors.hasOwnProperty(property)) {
          return;
        }
        const color = this.colors[property];
        cssText += `--lar-color-${property}: ${color.color};` + '\r\n';
        cssText += `--lar-color-${property}-shade: ${color.shade};` + '\r\n';
        cssText += `--lar-color-${property}-tint: ${color.tint};` + '\r\n';
        //
        cssText += `--lar-color-${property}-contrast: ${color.contrast};` + '\r\n';
        cssText += `--lar-color-${property}-contrast-rgb: ${color.contrastRGB};` + '\r\n';
        //
        cssText += `--lar-color-${property}-rgb: ${color.colorRGB};` + '\r\n';
      }
      //bg and text colors
      cssText += `--lar-background-color: ${this.appColors.bg};` + '\r\n';
      cssText += `--lar-text-color: ${this.appColors.text};` + '\r\n';
      for (let i = 50; i <= 1000; i+=50) {
        cssText += `--lar-background-color-step-${i}: ${colorHelpers.mix(this.appColors.text, this.appColors.bg, (i/10))};` + '\r\n';
        cssText += `--lar-text-color-step-${i}: ${colorHelpers.mix(this.appColors.bg, this.appColors.text, (i/10))};` + '\r\n';
      }
      // additional variables
      for (let property in this.variables) {
        if (!this.variables.hasOwnProperty(property)) {
          return;
        }
        const value = this.variables[property];
        if (value === null) {
          return;
        }
        cssText += `--${property}: ${value};` + '\r\n';
      }
      cssText = `:root {${'\r\n'}${cssText}}`;

      // send out the update event
      const event = document.createEvent('CustomEvent');
      event.initCustomEvent('message', true, true, { cssText, settings: this.settings });
      this.$refs['deviceframe'].contentDocument.dispatchEvent(event);
      this.cssText = cssText;
      //
    }
  },
};
</script>



<style lang="scss" scoped>
@import 'larva';

.list {
  border-bottom: 1px solid #ddd;
}
.padding {
  padding: 1rem 0;
}
.device-wrapper {
  display: flex;
  align-items: top;
  justify-content: center
}
.device {
  position: fixed;
  margin: 0 auto;
  --device-aspect-ratio: 2.125;
  --device-padding: 2rem;
  border-radius: 32px;
  box-shadow: 0 0 0 14px #090a0d, 0 0 0 17px #9fa3a8, 0 0 34px 17px rgba(0,0,0,.2);
  max-width: 320px;
  min-width: 320px;
  overflow: hidden;
  //padding-bottom: calc(var(--device-aspect-ratio) * 100%);
  max-height: 660px;
  min-height: 660px;
  z-index: 1;
  .ios-notch {
    fill: #090a0d;
    top: -1px;
    width: 165px;
    z-index: 2;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
  iframe {
    border: 0;
    width: 100%;
    height: 660px;
    margin: 0;
    padding: 0;
  }
}
.device:after {
    background-color: rgba(0,0,0,.5);
    border-radius: 2px;
    bottom: 6px;
    content: "";
    height: 4px;
    width: 35%;
    z-index: 1;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
</style>
